import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contact: "/Contact",
  rheology: "/Rheology",
};

const endpointsSlice = createSlice({
  name: "endpoints",
  initialState,
});

export const {} = endpointsSlice.actions;
export default endpointsSlice.reducer;
