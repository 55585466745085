import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Button/Button";
import "../Cards.css";

function ProductCard(props) {
  const navigate = useNavigate();
  const goToPage = () => {
    navigate(`${props.path}`);
  };

  return (
    <li className="cards__item">
      <Link className="cards__item__link" to={props.path}>
        <figure className="cards__item__pic-wrap">
          <img className="cards__item__img" src={props.src} />
        </figure>
        <div className="cards__item__description">
          <div className="cards__item__info">
            <h6 className="cards__item__header">{props.header}</h6>
            <p className="cards__item__text">{props.text}</p>
          </div>
          <Button onClick={goToPage}> Подробнее &rarr;</Button>
        </div>
      </Link>
    </li>
  );
}

export default ProductCard;
