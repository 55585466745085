import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const url = process.env.REACT_APP_API_URL;
const api = `${url}/api`;

const buildApiUrl = (endpoint) => `${api}${endpoint}`;

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    headers: { "Content-Type": "application/json charset=utf-8 " },
  }),

  endpoints: (builder) => ({
    get: builder.query({
      queryFn: async (endpoint, _quryApi, _options, fetchBaseQuery) => {
        let res = await fetchBaseQuery(`${endpoint}`);
        if (res.data) {
          console.log("SUCCESS:", res);
          return { data: res.data };
        } else {
          res.endpointAddress = endpoint;
          console.log("ERROR:", res);
          return { error: res };
        }
      },
    }),
    add: builder.mutation({
      query: ({ endpoint, id, body }) => ({
        url: `${endpoint}${id}`,
        method: "POST",
        body: JSON.stringify(body),
      }),
    }),
    update: builder.mutation({
      query: ({ endpoint, id, body }) => ({
        url: `${endpoint}${id}`,
        method: "PUT",
        body: JSON.stringify(body),
      }),
    }),
    remove: builder.mutation({
      query: ({ endpoint, id }) => ({
        url: `${endpoint}${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetQuery,
  useAddMutation,
  useUpdateMutation,
  useRemoveMutation,
} = apiSlice;
export { url, api, buildApiUrl as buildUrl };
