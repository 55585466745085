import React from "react";
import FeatureCard from "./FeatureCard";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { faThermometerHalf } from "@fortawesome/free-solid-svg-icons";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "../Cards.css";

function FeatureCardCMLife() {
  return (
    <div className="cards">
      <div className="cards__container">
        <div className="feature-cards__wrapper">
          <ul className="feature-card__item">
            <FeatureCard
              iconName={faCalculator}
              header="Расчет центрации"
              text="Расчет центрации в месте установки центраторов и между ними с учетом прогиба трубы. Максимальная точность расчетов на основе данных с тестов центраторов согласно API 10D."
            />
            <FeatureCard
              iconName={faThermometerHalf}
              header="Термодинамический расчет температуры"
              text="Современный алгоритм термодинамического расчета температуры позволяет более точно подобрать техническое задание для лаборатории."
            />
            <FeatureCard
              iconName={faCogs}
              header="Крутящий момент и сопротивление"
              text="Помогает определить риски недохождения ОК до проектного забоя и рассчитать возможность вращения ОК при цементировании с учетом реальных параметров ОК."
            />
            <FeatureCard
              iconName={faScrewdriverWrench}
              header="Установка мостов"
              text="Программный комплекс поддерживает расчет установки цементных мостов на баланс."
            />
          </ul>
          <ul className="feature-card__item">
            <FeatureCard
              iconName={faCloudUpload}
              header="Импорт кавернометрии"
              text="Возможность импорта кавернометрии позволяет добиться более точных расчетов по 3д вытеснению."
            />
            <FeatureCard
              iconName={faSearch}
              header="Сравнение кейсов"
              text="Возможность сравнить несколько результатов расчетов при разных параметров."
            />
            <FeatureCard
              iconName={faMobileScreenButton}
              header="Понятный интерфейc"
              text="Интуитивно понятный интерфейс, который делает процесс работы с приложением максимально комфортным и эффективным."
            />
            <FeatureCard
              iconName={faHeadset}
              header="Техническая поддержка"
              text="Постоянно обновляющаяся техническая поддержка, гарантирующая бесперебойную работу вашей лаборатории."
            />
          </ul>

          <ul className="feature-card__item__mobile">
            <FeatureCard
              iconName={faCalculator}
              header="Расчет центрации"
              text="Расчет центрации в месте установки центраторов и между ними с учетом прогиба трубы. Максимальная точность расчетов на основе данных с тестов центраторов согласно API 10D."
            />
            <FeatureCard
              iconName={faThermometerHalf}
              header="Термодинамический расчет температуры"
              text="Современный алгоритм термодинамического расчета температуры позволяет более точно подобрать техническое задание для лаборатории."
            />
          </ul>
          <ul className="feature-card__item__mobile">
            <FeatureCard
              iconName={faCogs}
              header="Крутящий момент и сопротивление"
              text="Помогает определить риски недохождения ОК до проектного забоя и рассчитать возможность вращения ОК при цементировании с учетом реальных параметров ОК."
            />
            <FeatureCard
              iconName={faScrewdriverWrench}
              header="Установка мостов"
              text="Программный комплекс поддерживает расчет установки цементных мостов на баланс."
            />
          </ul>
          <ul className="feature-card__item__mobile">
            <FeatureCard
              iconName={faCloudUpload}
              header="Импорт кавернометрии"
              text="Возможность импорта кавернометрии позволяет добиться более точных расчетов по 3д вытеснению."
            />
            <FeatureCard
              iconName={faSearch}
              header="Сравнение кейсов"
              text="Возможность сравнить несколько результатов расчетов при разных параметров."
            />
          </ul>
          <ul className="feature-card__item__mobile">
            <FeatureCard
              iconName={faMobileScreenButton}
              header="Понятный интерфейc"
              text="Интуитивно понятный интерфейс, который делает процесс работы с приложением максимально комфортным и эффективным."
            />
            <FeatureCard
              iconName={faHeadset}
              header="Техническая поддержка"
              text="Постоянно обновляющаяся техническая поддержка, гарантирующая бесперебойную работу вашей лаборатории."
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FeatureCardCMLife;
