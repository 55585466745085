import React, { useState } from "react";
import { useAddMutation } from "../../store/slices/apiSlice";
import { useSelector } from "react-redux";

import validator from "validator";
import { toast } from "react-custom-alert";

import { Button } from "../Button/Button";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";

import "react-custom-alert/dist/index.css";
import "./ApplicationForm.css";

function ApplicationForm() {
  const applicationEP = useSelector((state) => state.endpoints.contact);
  const [postApplication] = useAddMutation();

  const post = () => {
    postApplication({
      endpoint: applicationEP,
      id: "",
      body: {
        fullName: name,
        email: email,
        company: company,
        phoneNumber: "+7" + phone,
        requestType: applicationType.name,
        requestText: applicationText,
      },
    }).then((Response) => {
      console.log("Response.data", Response);
      if (Response.data) {
        toast.success("Ваши данные получены. Спасибо, что оставили заявку!");
        clearData();
      } else {
        toast.error(
          "Что-то пошло не так. Пожалуйста, попробуйте отправить заявку позже."
        );
      }
    });
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [applicationType, setApplicationType] = useState("");
  const [applicationText, setApplicationText] = useState("");

  const applicationTypes = [
    { name: "Обучение", code: "studying" },
    { name: "Оборудование", code: "equipment" },
    { name: "Инженерное сопровождение", code: "engineering" },
    { name: "Другое", code: "other" },
  ];

  function clearData() {
    setName("");
    setEmail("");
    setCompany("");
    setPhone("");
    setApplicationType("");
    setApplicationText("");
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (
      name == "" ||
      email == "" ||
      company == "" ||
      applicationType == "" ||
      applicationText == ""
    ) {
      toast.warning("Пожалуйста, заполните все необходимые поля.");
    } else if (!validator.isEmail(email)) {
      toast.error("Пожалуйста, проверьте правильность введенного email.");
    } else {
      post();
    }
  }

  return (
    <div className="application-form">
      <form className="application-form__inner">
        <div className="input-section">
          <div className="input__wrapper">
            <label htmlFor="name">ФИО</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              required={true}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input__wrapper">
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              required={true}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input__wrapper">
            <label htmlFor="company">Компания</label>
            <input
              type="text"
              id="company"
              name="company"
              value={company}
              required={true}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div className="input__wrapper">
            <label htmlFor="phone">Телефон</label>
            <InputMask
              className="phone__wrapper"
              id="phone"
              mask="+7 (999) 999-99-99"
              value={phone}
              required={true}
              unmask={true}
              onChange={(e) => setPhone(e.value)}
            />
          </div>

          <div className="input__wrapper">
            <label htmlFor="applicationType">Тип заявки</label>

            <Dropdown
              value={applicationType}
              onChange={(e) => setApplicationType(e.value)}
              options={applicationTypes}
              optionLabel="name"
              className="w-full md:w-14rem"
            />
          </div>

          <div className="textarea__wrapper">
            <label htmlFor="applicationText">Текст заявки</label>
            <textarea
              id="applicationText"
              name="applicationText"
              value={applicationText}
              required={true}
              onChange={(e) => setApplicationText(e.target.value)}
            />
          </div>
        </div>
      </form>
      <div className="application-form__btn-wrapper">
        <Button type="submit" onClick={handleSubmit}>
          Отправить
        </Button>
      </div>
    </div>
  );
}

export default ApplicationForm;
