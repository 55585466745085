import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavbarDropdown from "../NavbarDropdown/NavbarDropdown";
import Logo from "../../images/logo.png";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <nav className="navbar">
      <Link to="/#home" className="navbar-logo" onClick={closeMobileMenu}>
        <img src={Logo} className="navbar-logo" />
        ИРТ
      </Link>
      <div className="menu-icon" onClick={handleClick}>
        <i className={click ? "fas fa-times" : "fas fa-bars"} />
      </div>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li className="nav-item">
          <Link to="/#about-us" className="nav-links" onClick={closeMobileMenu}>
            О НАС
          </Link>
        </li>

        <li
          className="nav-item-desktop"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Link to="/#products" className="nav-links" onClick={closeMobileMenu}>
            НАШИ ПРОДУКТЫ
          </Link>
          {dropdown && <NavbarDropdown />}
        </li>

        <li className="nav-item-mobile" onClick={() => setDropdown(!dropdown)}>
          <Link to="/#products" className="nav-links">
            НАШИ ПРОДУКТЫ
            <i className="pi pi-sort-down-fill" />
          </Link>
          {dropdown && <NavbarDropdown onClickEvent={closeMobileMenu} />}
        </li>

        {/*<li className="nav-item">
          <Link
            to="/#companies"
            className="nav-links"
            onClick={closeMobileMenu}
          >
            С НАМИ РАБОТАЮТ
          </Link>
        </li>*/}

        <li className="nav-item">
          <Link to="/#contacts" className="nav-links" onClick={closeMobileMenu}>
            КОНТАКТЫ
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
