import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Cards.css";

function FeatureCard(props) {
  return (
    <li className="feature-card">
      <div className="feature-card__header">
        <FontAwesomeIcon
          icon={props.iconName}
          size="2xs"
          style={{ color: "#212227" }}
        />
        <p className="feature-card__text">{props.header}</p>
      </div>
      <div className="feature-card__description">
        <p>{props.text}</p>
      </div>
    </li>
  );
}

export default FeatureCard;
