import React from "react";
import ContactCard from "./ContactCard";
import contactImg1 from "../../../images/contact-img-1.jpg";
import contactImg2 from "../../../images/contact-img-2.jpg";
import contactImg3 from "../../../images/contact-img-3.jpg";
import contactImg4 from "../../../images/contact-img-4.jpg";
import contactImg5 from "../../../images/contact-img-5.jpg";
import "../Cards.css";

function ContactCardGrid() {
  return (
    <div className="contacts">
      <div className="contacts-custom">
        <ContactCard
          contactImg={contactImg1}
          contactSurname="Рязанов"
          contactName="Роман"
          contactSecondName="Николаевич"
          contactJob="Директор"
          contactEmail="RRyazanov@i-d-tech.com"
          contactTel="+7(922)478-38-33"
        />
        <ContactCard
          contactImg={contactImg2}
          contactSurname="Ястребов"
          contactName="Илья"
          contactSecondName="Динарович"
          contactJob="Заместитель директора"
          contactEmail="IDYastrebov@i-d-tech.com"
          contactTel="+7(912)927-62-55"
        />
        <ContactCard
          contactImg={contactImg3}
          contactSurname="Сулейманов"
          contactName="Рамис"
          contactSecondName="Робертович"
          contactJob="Руководитель направления «РИР»"
          contactEmail="RRSuleimanov@i-d-tech.com"
          contactTel="+7(952)677-51-02"
        />
        <ContactCard
          contactImg={contactImg4}
          contactSurname="Мурашко"
          contactName="Александр"
          contactSecondName="Евгеньевич"
          contactJob="Руководитель направления разработки ПО"
          contactEmail="AEMurashko@i-d-tech.com"
          contactTel="+7(904)476-49-88"
        />
        <ContactCard
          contactImg={contactImg5}
          contactSurname="Билетников"
          contactName="Егор"
          contactSecondName="Юрьевич"
          contactJob="Руководитель направления «Инженерное сопровождение»"
          contactEmail="EYuBiletnikov@i-d-tech.com"
          contactTel="+7(922)483-92-33"
        />
      </div>
    </div>
  );
}

export default ContactCardGrid;
