import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button/Button";
import ApplicationForm from "../components/ApplicationForm/ApplicationForm";
import FeatureCardCMTest from "../components/Cards/FeatureCard/FeatureCardCMTest";
import CarouselCemTest from "../components/Carousel/CarouselCemTest";
import "../App.css";

export default function CemTest() {
  const navigate = useNavigate();
  const goToApplication = () => {
    navigate("/cem-test#cemtest-application");
  };

  return (
    <>
      <div className="product" id="cem-test">
        <div className="product-description">
          <h4>Cemtest</h4>
          <p className="product-description-header">
            Информационная система автоматизации лаборатории CMTest
          </p>
          <p>
            Информационная система CMTest представляет собой программное
            обеспечение, разработанное специально для автоматизации процессов в
            лабораториях, занимающихся тампонажными растворами. <br />
            <br />
            Цель системы - повышение производительности и обеспечение точности
            данных, получаемых в ходе лабораторных исследований.
          </p>

          <div className="product__btn-wrapper">
            <Button onClick={goToApplication}>Связаться с нами</Button>
          </div>
        </div>
        <div className="product-section-wrap">
          <p className="product-description-header">Особенности системы</p>
          <FeatureCardCMTest />
        </div>
        <div className="product-section-wrap">
          <p className="product-description-header">Модули системы</p>
          <div className="product__modules">
            <div className="carousel-wrapper">
              <CarouselCemTest />
            </div>
          </div>
        </div>
      </div>
      <div className="application-form__wrapper" id="cemtest-application">
        <h4>Оставьте заявку</h4>
        <ApplicationForm />
      </div>
    </>
  );
}
