import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button/Button";
import ApplicationForm from "../components/ApplicationForm/ApplicationForm";
import FeatureCardCMLife from "../components/Cards/FeatureCard/FeatureCardCMLife";
import CarouselCemLife from "../components/Carousel/CarouselCemLife";
import "../App.css";

export default function CemLife() {
  const navigate = useNavigate();
  const goToApplication = () => {
    navigate("/cem-life#cemlife-application");
  };
  return (
    <>
      <div className="product" id="cem-life">
        <div className="product-description">
          <h4>Cemlife</h4>
          <p className="product-description-header">
            Программный комплекс для расчетов при цементировании CemLife
          </p>
          <p>
            CemLife представляет собой программное обеспечение, разработанное
            специально для гидравлических расчетов при цементировании скважин.
            <br />
            <br />
            Цель системы - повышение качества цементирования и уход от возможных
            инцидентов.
          </p>

          <div className="product__btn-wrapper">
            <Button onClick={goToApplication}>Связаться с нами</Button>
          </div>
        </div>
        <div className="product-section-wrap">
          <p className="product-description-header">Особенности системы</p>
          <FeatureCardCMLife />
        </div>
        <div className="product-section-wrap">
          <p className="product-description-header">Модули системы</p>
          <div className="product__modules">
            <div className="carousel-wrapper">
              <CarouselCemLife />
            </div>
          </div>
        </div>
      </div>
      <div className="application-form__wrapper" id="cemlife-application">
        <h4>Оставьте заявку</h4>
        <ApplicationForm />
      </div>
    </>
  );
}
