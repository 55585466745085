import React from "react";
import ProductCard from "./ProductCard";
import card1Img from "../../../images/img-3.jpg";
import card2Img from "../../../images/img-4.jpg";
import card3Img from "../../../images/img-5.jpg";
import card4Img from "../../../images/img-6.jpg";
import card5Img from "../../../images/img-7.jpg";
import card6Img from "../../../images/img-8.jpg";
import card7Img from "../../../images/img-9.jpg";
import "../Cards.css";

function ProductCardGrid() {
  return (
    <div className="cards">
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <ProductCard
              src={card2Img}
              header="CemLife"
              text="Программное обеспечение для расчетов при цементировании"
              path="/cem-life#cem-life"
            />
            <ProductCard
              src={card3Img}
              header="CemTest"
              text="Информационная система для автоматизации лаборатории.
              Система представляет собой программное обеспечение, разработанное специально для автоматизации процессов в лабораториях, занимающихся тампонажными растворами"
              path="/cem-test#cem-test"
            />
            <ProductCard
              src={card1Img}
              header="TS-400"
              text="Современное оборудование тестирования цементного камня"
              path="/ts400#ts400"
            />
          </ul>
          <ul className="cards__items">
            <ProductCard
              src={card4Img}
              header="Инженерное сопровождение"
              text="Наш персонал на 90% состоит из инженеров высочайшего класса, которые могут предоставить Вам расчеты и инженерные решения для любой задачи"
              path="/engineering-support#engineering-support"
            />
            {/*<ProductCard
              src={card5Img}
              header="Ремонтно-изоляционные работы"
              text="Использование уникальной формулы позволит прослужить вашей скважине еще долгие годы"
              path="/"
            />*/}
            <ProductCard
              src={card6Img}
              header="Цем. головы"
              text="Новейшее оборудование позволит Вам добиться максимального качества выполняемых операций и снизить риски и затраты на проведение ремонтных работ"
              path="/cem-heads#cem-heads"
            />
            <ProductCard
              src={card7Img}
              header="Обучение персонала"
              text="Наши лучшие специалисты с многолетним опытом работы в ведущих сервисных компаниях предоставляют услуги по проведению технических консультаций в области наклонно-направленного бурения скважин, крепления обсадных колонн, буровых растворов, ГРП и супервайзинга в бурении"
              path="/staff-training#staff-training"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProductCardGrid;
