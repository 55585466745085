import React from "react";
import "../App.css";

export default function CemHeads() {
  return (
    <div className="page-in-development" id="cem-heads">
      <h4>Данная страница находится в разработке</h4>
    </div>
  );
}
