import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PrimeReactProvider } from "primereact/api";
import store from "./store/store";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/*<React.StrictMode>*/}
    <PrimeReactProvider>
      <App />
    </PrimeReactProvider>
    {/*</React.StrictMode>*/}
  </Provider>
);
