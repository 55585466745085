import React from "react";
/*---components--- */
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
/*---pages--- */
import Home from "./pages/Home";
import CemLife from "./pages/CemLife";
import CemTest from "./pages/CemTest";
import TS400 from "./pages/TS400";
import EngineeringSupport from "./pages/EngineeringSupport";
import CemHeads from "./pages/CemHeads";
import StaffTraining from "./pages/StaffTraining";
import Rheology from "./pages/Rheology/Rheology";
/*---hooks--- */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToAnchor from "./hooks/ScrollToAnchor";
import { ToastContainer } from "react-custom-alert";
/*---styles--- */
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import "react-custom-alert/dist/index.css";
import "./App.css";
import { checkAppVersion } from "./utils/checkAppVersions";
import ScrollToTop from "./hooks/ScrollToTop";

checkAppVersion();

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ScrollToAnchor/>
      <ToastContainer floatingTime={5000} />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cem-life" element={<CemLife />} />
        <Route path="/cem-test" element={<CemTest />} />
        <Route path="/ts400" element={<TS400 />} />
        <Route path="/engineering-support" element={<EngineeringSupport />} />
        <Route path="/cem-heads" element={<CemHeads />} />
        <Route path="/staff-training" element={<StaffTraining />} />
        <Route path="/rheology-calculator" element={<Rheology />} />
        <Route path="/rheologycalculator" element={<Rheology />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
