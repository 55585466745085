import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "../Cards.css";

function ContactCard(props) {
  return (
    <div className="contact-card">
      <div className="contact-card__personal-info">
        <img src={props.contactImg} />
        <div className="contact-card__name">
          <p>{props.contactSurname}</p>
          <p>{props.contactName}</p>
          <p>{props.contactSecondName}</p>
        </div>
      </div>
      <div className="contact-card__info">
        <div className="contact-card__name-hidden">
          <p>{props.contactSurname}</p>
          <p>{props.contactName}</p>
          <p>{props.contactSecondName}</p>
        </div>
        <div className="contact-card__contacts-job-wrapper">
          <p>{props.contactJob}</p>
        </div>
        <div className="contact-card__contacts-wrapper">
          <div className="contact-card__contacts">
            <FontAwesomeIcon
              icon={faEnvelope}
              size="3xs"
              style={{ color: "#212227" }}
            />
            {props.contactEmail}
          </div>
          <div className="contact-card__contacts">
            <FontAwesomeIcon
              icon={faMobileScreenButton}
              size="3xs"
              style={{ color: "#212227" }}
            />
            {props.contactTel}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactCard;
