import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Logo from "../../images/logo-white.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={Logo} />
        <div className="footer-logo-name">
          <p>Intelligence-Development-Technology</p>
          <p>ООО "Интеллект-Развитие-Технологии"</p>
        </div>
      </div>
      <div className="footer-info">
        <p>ООО "ИРТ"</p>
        <p>ИНН/КПП:7203368755/720301001</p>
        <p>ОГРН: 1167232050050</p>
      </div>
      <div className="footer-contry">
        <p>Россия, г. Тюмень 625001</p>
      </div>
    </div>
  );
}

export default Footer;
