import React from "react";
/*---components---*/
import ProductCardGrid from "../components/Cards/ProductCard/ProductCardGrid";
import CompanyCardGrid from "../components/Cards/CompanyCard/CompanyCardGrid";
import ContactCardGrid from "../components/Cards/ContactCard/ContactCardGrid";
import ApplicationForm from "../components/ApplicationForm/ApplicationForm";
/*---images--- */
import mainImage from "../images/img-1.jpg";
import aboutUsImg from "../images/img-2.jpg";
/*---styles--- */
import "../App.css";

export default function Home() {
  return (
    <div className="home" id="home">
      <div className="home-main-img">
        <img src={mainImage} />
      </div>
      <div className="about-us" id="about-us">
        <div className="about-us__header">
          <h4>О нас</h4>
        </div>
        <div className="about-us__content">
          <div className="about-us__img">
            <div className="about-us__img-bground" />
            <div className="about-us__image">
              <img src={aboutUsImg} />
            </div>
          </div>
          <div className="about-us__text">
            <h6>Интеллект-Развитие-Технологии</h6>
            <p>
              ООО “Интеллект-Развитие-Технологии” - компания с многолетним
              опытом, занимающаяся инновациями в области цементирования
              нефтегазовых скважин и предоставляющая выдающиеся решения в этой
              области.
            </p>
            <p>
              Наши услуги включают в себя обучение персонала, инженерное
              сопровождение и выполнение ремонтно-изоляционных работ. Кроме
              того, мы также занимаемся разработкой программного обеспечения,
              что позволяет нам занимать лидирующие места в данной отрасли.
            </p>
            <p>
              <b>Обучение персонала:</b> <br />
              Мы предоставляем профессиональное обучение для персонала,
              работающего в нефтегазовой промышленности. Наши курсы позволяют
              повысить квалификацию специалистов и обеспечивают безопасность и
              эффективность операций.
            </p>
            <p>
              <b>Инженерное сопровождение:</b>
              <br />
              Наши опытные инженеры обеспечивают комплексное сопровождение
              скважинных проектов. Мы гарантируем высокий уровень технической
              поддержки и контроля качества.
            </p>
            <p>
              <b>Ремонтно-изоляционные работы:</b> <br />
              Мы выполняем работы по ремонту и изоляции скважин с использованием
              современных технологий и материалов. Наша команда гарантирует
              надежность и долговечность решений.
            </p>
          </div>
          <div className="about-us__img-mobile">
            <div className="about-us__img-bground-mobile" />
            <div className="about-us__image-mobile">
              <img src={aboutUsImg} />
            </div>
          </div>
        </div>
      </div>
      <div className="product-cards__wrapper" id="products">
        <h4>Наши продукты</h4>
        <ProductCardGrid />
      </div>
      {/*<div className="company-cards__wrapper" id="companies">
        <h4>С нами работают</h4>
        <CompanyCardGrid />
  </div>*/}
      <div className="application-form__wrapper">
        <h4>Оставьте заявку</h4>
        <ApplicationForm />
      </div>
      <div className="contact-cards__wrapper" id="contacts">
        <h4>Контакты</h4>
        <ContactCardGrid />
      </div>
    </div>
  );
}
