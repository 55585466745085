import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";
import { Toast } from "primereact/toast";
import { useAddMutation } from "../../store/slices/apiSlice";
import { useSelector } from "react-redux";
import styles from "./RheologyCalculator.module.css";
import { InputText } from "primereact/inputtext";
import { ContextMenu } from "primereact/contextmenu";

export default function RheologyCalculator() {
  const cm = useRef(null);
  const [temperature, setTemperature] = useState(22);
  const [density, setDensity] = useState(1.5);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [k1, setK1] = useState();
  const [k2, setK2] = useState();
  const [m, setM] = useState();
  const [n, setN] = useState();
  const [yp, setYP] = useState();
  const [pv, setPV] = useState();
  const [k, setK] = useState();
  const [model, setModel] = useState();


  /*---response-data---*/
  const [ rheologicalProp, setRheologicalProp] = useState([]);

  /*---viscometer-dropdown---*/
  const viscometerGeometry = [
    { name: "R1B1", code: "B1BobSleeve" },
    { name: "R1B2", code: "B2BobSleeve" },
    { name: "Вручную", code: "Custom" },
  ];

  const [selectedViscometerGeometry, setSelectedViscometerGeometry] =
    useState(viscometerGeometry[0]);

  useEffect(() => {
    switch (selectedViscometerGeometry?.code) {
      case "B1BobSleeve": {
        setK1(1.705);
        setK2(0.5099);
        setInputDisabled(true);
        break;
      }
      case "B2BobSleeve": {
        setK1(0.378);
        setK2(1);
        setInputDisabled(true);
        break;
      }
      case "Custom": {
        setK1(null);
        setK2(null);
        setInputDisabled(false);
        break;
      }
      default:
        return;
    }
  }, [selectedViscometerGeometry]);

  /*---toast---*/
  const toast = useRef(null);

  /*---chart-datatable---*/
  const viscosemeterData = [
    {
      rotationSpeed: 600,
      viscometerIndicator: null,
    },
    {
      rotationSpeed: 300,
      viscometerIndicator: null,
    },
    {
      rotationSpeed: 200,
      viscometerIndicator: null,
    },
    {
      rotationSpeed: 100,
      viscometerIndicator: null,
    },
    {
      rotationSpeed: 60,
      viscometerIndicator: null,
    },
    {
      rotationSpeed: 30,
      viscometerIndicator: null,
    },
    {
      rotationSpeed: 6,
      viscometerIndicator: null,
    },
    {
      rotationSpeed: 3,
      viscometerIndicator: null,
    },
  ];
  const [chartDatatable, setChartDatatable] = useState(viscosemeterData);
  const [selectedChartData, setSelectedChartData] = useState();

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    if (newValue != null) {
      rowData[field] = newValue;
    } else event.preventDefault();
  };

  /*---datatable-num-editor---*/
  const numEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        useGrouping={false}
        maxFractionDigits={5}
        className="custom-input-small"  
      />
    );
  };

  const modelTemplate = (model) => {
    if (model === 'Newtonian')
      return 'Ньютоновская'
    if (model === 'PowerLaw')
      return 'Степенная'
    if (model === 'BinghamPlastic')
      return 'Бингам пластик'
    if (model === 'HerschelBulkley')
      return 'Гершель-Бакли'
  }

  /*---post-data---*/
  const rheologyEP = useSelector((state) => state.endpoints.rheology);
  const [addData] = useAddMutation();

  const calculateRheology = () => {
    let array = [...chartDatatable];
    const count = chartDatatable.filter(item => item.viscometerIndicator !== null).length;

    if (
      !selectedViscometerGeometry?.code ||
      !density ||
      !temperature ||
      !k1 ||
      !k2 ||
      count < 4
    ) {
      toast.current.show({
        severity: "error",
        summary: "Заполнены не все поля ввода",
        detail: "Пожалуйста, введите необходимую информацию",
        life: 3000,
      });
    } else {
      let body = {
        density: density,
        temperature: temperature,
        rSpeed600: chartDatatable.find((item) => item.rotationSpeed === 600)
         .viscometerIndicator,
        rSpeed300: chartDatatable.find((item) => item.rotationSpeed === 300)
          .viscometerIndicator,
        rSpeed200: chartDatatable.find((item) => item.rotationSpeed === 200)
          .viscometerIndicator,
        rSpeed100: chartDatatable.find((item) => item.rotationSpeed === 100)
          .viscometerIndicator,
        rSpeed60: chartDatatable.find((item) => item.rotationSpeed === 60)
          .viscometerIndicator,
        rSpeed30: chartDatatable.find((item) => item.rotationSpeed === 30)
          .viscometerIndicator,
        rSpeed6: chartDatatable.find((item) => item.rotationSpeed === 6)
          .viscometerIndicator,
        rSpeed3: chartDatatable.find((item) => item.rotationSpeed === 3)
          .viscometerIndicator,
        k1: k1,
        k2: k2,
        rheometerType: selectedViscometerGeometry.code,
      };

      toast.current.show({
        severity: "info",
        summary: "Данные обрабатываются...",
        detail: "Пожалуйста, подождите",
        life: 60000,
        sticky: true
      });

      addData({
        endpoint: rheologyEP,
        id: "",
        body: body,
      }).then((response) => {
        if (response?.data) {
          let _rheologicalProp = [...response?.data];
          setRheologicalProp(_rheologicalProp);
          toast.current.clear();
          toast.current.show({
            severity: "success",
            summary: "Успешно",
            life: 1000,
          });
        } else {
          toast.current.clear();
          toast.current.show({
            severity: "error",
            summary: "Что-то пошло не так",
            detail: "Пожалуйста, попробуйте позже",
            life: 3000,
          });
        }
      });
    }
  };

  const clearData = () => {
    setTemperature(22);
    setDensity(1.5);
    setInputDisabled();
    setSelectedViscometerGeometry(viscometerGeometry[0]);
    setK1();
    setK2();
    setM();
    setN();
    setYP();
    setPV();
    setK();
    setRheologicalProp([]);
    setChartDatatable(viscosemeterData);
    setSelectedChartData();
  };
  
  /*---cell-templates---*/
  const floatTemplate = (value, decimals = 3) => {
    return value.toFixed(decimals);
  };

  /*---chart---*/
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );

    let values = [].concat(
      chartDatatable
        .filter(x => x.viscometerIndicator != null)
        .map(x => ({
          x: x.rotationSpeed,
          y: x.viscometerIndicator
        }))
    ).sort((a, b) => a.x - b.x);

    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const data = {
      datasets: [
        {
          label: "Показатель вискозиметра (°)",
          data: values,
          fill: false,
          borderColor: documentStyle.getPropertyValue("--green-500"),
          showLine: true,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 1,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          type: 'linear',
          position: 'bottom',
          ticks: {
            color: textColorSecondary,
            stepSize: 100, // Шаг для оси X
          },
          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Скорость вращения (об/мин)",
            color: textColor,
          },
        },
        y: {
          type: 'linear',
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Показатель вискозиметра (°)",
            color: textColor,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [rheologicalProp]);

  const copyToClipboard = () => {
    const dataToCopy = chartDatatable
      .filter(item => item.viscometerIndicator !== null) // Фильтрация строк, где viscometerIndicator не равен null
      .map(item => `${item.rotationSpeed}:${item.viscometerIndicator}`)
      .join('\n');
    
    navigator.clipboard.writeText(dataToCopy);
    toast.current.show({
      severity: "info",
      summary: "Скопировано",
      detail: "Данные скопированы в буфер обмена",
      life: 3000,
    });
  };
  
  const pasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const rows = text.split('\n');
      const newData = rows.map(row => {
        const [rotationSpeed, viscometerIndicator] = row.split(':').map(Number);
        return { rotationSpeed, viscometerIndicator };
      }).filter(item => !isNaN(item.viscometerIndicator)); // Фильтрация строк с валидным значением viscometerIndicator
  
      setChartDatatable(prevData =>
        prevData.map(item => {
          const newItem = newData.find(newItem => newItem.rotationSpeed === item.rotationSpeed);
          return newItem ? { ...item, viscometerIndicator: newItem.viscometerIndicator } : item;
        })
      );
  
      toast.current.show({
        severity: "info",
        summary: "Вставлено",
        detail: "Данные вставлены из буфера обмена",
        life: 3000,
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не удалось вставить данные",
        life: 3000,
      });
    }
  };
  

  const contextMenuItems = [
    { label: "Копировать", icon: "pi pi-copy", command: copyToClipboard },
    { label: "Вставить", icon: "pi pi-pencil", command: pasteFromClipboard },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {/* <div className={styles.column}>
          <div className={styles.input_wrapper}>
            <label>Температура, °C</label>
            <InputNumber
              className="custom-input"
              inputId="temperature"
              useGrouping={false}
              maxFractionDigits={5}
              value={temperature}
              onValueChange={(e) => setTemperature(e.value)}
            />
          </div>
          <div className={styles.input_wrapper}>
            <label>
              Плотность жидкости, г/см<sup>3</sup>
            </label>
            <InputNumber
              className="custom-input"
              inputId="density"
              useGrouping={false}
              maxFractionDigits={5}
              value={density}
              onValueChange={(e) => setDensity(e.value)}
            />
          </div>
          <div className={styles.input_wrapper}>
            <label>Геометрия вискозиметра:</label>
            <Dropdown
              value={selectedViscometerGeometry}
              onChange={(e) => setSelectedViscometerGeometry(e.value)}
              options={viscometerGeometry}
              optionLabel="name"
              className="custom-dropdown"
            />
          </div>
          <div className={styles.input_wrapper}>
            <label>K1:</label>
            <InputNumber
              className="custom-input"
              inputId="k1"
              useGrouping={false}
              maxFractionDigits={5}
              value={k1}
              onValueChange={(e) => setK1(e.value)}
              disabled={inputDisabled}
              tooltip={
                inputDisabled
                  ? "Для ввода значения выберете ручной режим вискозиметра"
                  : ""
              }
            />
          </div>
          <div className={styles.input_wrapper}>
            <label>K2:</label>
            <InputNumber
              className="custom-input"
              inputId="k2"
              useGrouping={false}
              maxFractionDigits={5}
              value={k2}
              onValueChange={(e) => setK2(e.value)}
              disabled={inputDisabled}
              tooltip={
                inputDisabled
                  ? "Для ввода значения выберете ручной режим вискозиметра"
                  : ""
              }
            />
          </div>
        </div> */}

        <div className="card">
          <Toast ref={toast} position="bottom-center" />

          <DataTable
            value={chartDatatable}
            className={styles.datatable_full_w}
            tableStyle={{ minWidth: "20rem" }}
            selectionMode="single"
            selection={selectedChartData}
            onSelectionChange={(e) => setSelectedChartData(e.value)}
            dataKey="rotationSpeed"
            editMode="cell"
            onContextMenu={(e) => cm.current.show(e.originalEvent)}
          >
            <Column field="rotationSpeed" header="Скорость вращения (об/мин)" />
            <Column
              field="viscometerIndicator"
              header="Показатель вискозиметра (°)"
              editor={ (options) => numEditor(options) }
              // onCellEditInit={ () => setTimeout(setCalcBtnDisabled(true),100) }
              // onCellEditCancel={() => setTimeout(setCalcBtnDisabled(true),100)}
              onCellEditComplete={onCellEditComplete}
            />
          </DataTable>
          <div className={styles.desktop_btn}>
            <Button className={ styles.btn }
              onClick={ () => setTimeout(calculateRheology, 50) }>Рассчитать</Button>
          </div>
          <div className={styles.desktop_btn}>
            <button className="btn-outlined" onClick={clearData}>
              Сбросить
            </button>
          </div>
        </div>

        <div className={styles.mobile_btn}>
          <Button onClick={()=> setTimeout(calculateRheology, 50)}>Рассчитать</Button>
        </div>
      </div>

      <div className={styles.column_left_border}>
        <div className={ styles.desktop_datatable }>
          <ContextMenu model={contextMenuItems} ref={cm} />
          <DataTable
            value={rheologicalProp}
            size="small"
            className={styles.datatable_full_w}
            selectionMode="single"
            dataKey="m"
          >
            <Column field="n" header="n" body={(row) => floatTemplate(row.n, 2)} />
            <Column field="yp" header="YP (Па)" body={(row) => floatTemplate(row.yp,3)}/>
            <Column field="pv" header="PV (мПа*с)" body={(row) => floatTemplate(row.pv, 2)} />
            <Column field="k" header="K (Па*с*n')" body={(row) => floatTemplate(row.k, 5)} />
            <Column field="model" header="Модель" body={(row) => modelTemplate(row.model)} />
            
          </DataTable>
        </div>

        <div className="card" style={{ width: "100%" }}>
          <Chart
            type="line"
            data={chartData}
            options={chartOptions}
            className={styles.chart}
          />
        </div>

        <div className={styles.mobile_btn}>
          <button className="btn-outlined" onClick={clearData}>
            Сбросить
          </button>
        </div>
      </div>
    </div>
  );
}
