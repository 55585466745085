import React from "react";
import "../App.css";

export default function TS400() {
  return (
    <div className="page-in-development" id="ts400">
      <h4>Данная страница находится в разработке</h4>
    </div>
  );
}
