import store from '../store/store';
import { persistor } from '../store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

export const checkAppVersion = () => {
    const appVersion = process.env.REACT_APP_VERSION; // Получаем версию из .env
    const storedVersion = localStorage.getItem('appVersion'); // Получаем сохраненную версию

    if (storedVersion !== appVersion) {
        persistor.purge();
        localStorage.setItem('appVersion', appVersion); // Сохраняем новую версию
        console.log(`Application version updated from ${storedVersion} to ${appVersion}. Cache cleared.`);
    } else {
        console.log(`Application version is up-to-date: ${appVersion}`);
    }
};