import React from "react";
import FeatureCard from "./FeatureCard";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faPersonCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import "../Cards.css";

function FeatureCardCMTest() {
  return (
    <div className="cards">
      <div className="cards__container">
        <div className="feature-cards__wrapper">
          <ul className="feature-card__item">
            <FeatureCard
              iconName={faPersonCircleCheck}
              header="Гибкая интеграция"
              text="Возможность частичной или полной интеграции с вашими текущими сервисами, обеспечивая максимальную совместимость и удобство."
            />
            <FeatureCard
              iconName={faCalculator}
              header="Калькулятор смесей"
              text="Уникальный инструмент для быстрого и удобного подбора рецептуры, обеспечивающий точную настройку плотности."
            />
            <FeatureCard
              iconName={faDatabase}
              header="Перенос базы данных"
              text="Полный перенос вашей текущей базы данных, гарантирующий беспроблемный переход к новым возможностям приложения."
            />
            <FeatureCard
              iconName={faFileContract}
              header="Электронный паспорт"
              text="Возможность хранить всю необходимую документацию электронно, обеспечивая надежное и структурированное хранение информации."
            />
          </ul>
          <ul className="feature-card__item">
            <FeatureCard
              iconName={faScrewdriverWrench}
              header="Шаблоны"
              text="Создание готовых наборов тестов для моментального ускорения работы лаборатории и инженерного состава."
            />
            <FeatureCard
              iconName={faMobileScreenButton}
              header="Понятный интерфейс"
              text="Интуитивно понятный интерфейс, который делает процесс работы с приложением максимально комфортным и эффективным."
            />
            <FeatureCard
              iconName={faBell}
              header="Система уведомлений"
              text="Удобная и гибкая система уведомлений, позволяющая быть в курсе всех событий вашей лаборатории в режиме реального времени."
            />
            <FeatureCard
              iconName={faHeadset}
              header="Техническая поддержка"
              text="Постоянно обновляющаяся техническая поддержка, гарантирующая бесперебойную работу вашей лаборатории."
            />
          </ul>

          <ul className="feature-card__item__mobile">
            <FeatureCard
              iconName={faPersonCircleCheck}
              header="Гибкая интеграция"
              text="Возможность частичной или полной интеграции с вашими текущими сервисами, обеспечивая максимальную совместимость и удобство."
            />
            <FeatureCard
              iconName={faCalculator}
              header="Калькулятор смесей"
              text="Уникальный инструмент для быстрого и удобного подбора рецептуры, обеспечивающий точную настройку плотности."
            />
          </ul>
          <ul className="feature-card__item__mobile">
            <FeatureCard
              iconName={faDatabase}
              header="Перенос базы данных"
              text="Полный перенос вашей текущей базы данных, гарантирующий беспроблемный переход к новым возможностям приложения."
            />
            <FeatureCard
              iconName={faFileContract}
              header="Электронный паспорт"
              text="Возможность хранить всю необходимую документацию электронно, обеспечивая надежное и структурированное хранение информации."
            />
          </ul>
          <ul className="feature-card__item__mobile">
            <FeatureCard
              iconName={faScrewdriverWrench}
              header="Шаблоны"
              text="Создание готовых наборов тестов для моментального ускорения работы лаборатории и инженерного состава."
            />
            <FeatureCard
              iconName={faMobileScreenButton}
              header="Понятный интерфейс"
              text="Интуитивно понятный интерфейс, который делает процесс работы с приложением максимально комфортным и эффективным."
            />
          </ul>
          <ul className="feature-card__item__mobile">
            <FeatureCard
              iconName={faBell}
              header="Система уведомлений"
              text="Удобная и гибкая система уведомлений, позволяющая быть в курсе всех событий вашей лаборатории в режиме реального времени."
            />
            <FeatureCard
              iconName={faHeadset}
              header="Техническая поддержка"
              text="Постоянно обновляющаяся техническая поддержка, гарантирующая бесперебойную работу вашей лаборатории."
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FeatureCardCMTest;
