import React from "react";
import Slider from "react-slick";
/*---images--- */
import advancedSearch from "../../images/AdvancedSearch.png";
import comparison from "../../images/Comparison.png";
import samples from "../../images/Samples.png";
import equipment from "../../images/Equipment.png";
import dashboard from "../../images/Dashboard.png";
import report from "../../images/Report.png";
import blendCheck from "../../images/BlendCheck.png";
/*---styles--- */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselCemTest = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">
              Расширенный поиск
            </p>
            <p className="product__modules-description-text">
              Модуль обеспечивает эффективный поиск рецептур в базе данных Вашей
              лаборатории. Введение фильтров и параметров позволяет быстро
              находить нужные рецептуры, сокращая время поиска до минимума.
            </p>
          </div>
          <img src={advancedSearch} alt="slide-1" />
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">Сравнение</p>
            <p className="product__modules-description-text">
              Модуль предоставляет возможность анализа и сравнения нескольких
              рецептур, что позволяет выбрать оптимальный вариант в соответствии
              с конкретными задачами и требованиями.
            </p>
          </div>
          <img src={comparison} alt="slide-2" />
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">Пробы</p>
            <p className="product__modules-description-text">
              Модуль предоставляет систему учета и логистики всех проб,
              собранных в лаборатории. <br />
              Это обеспечивает точный и структурированный анализ данных для
              последующего использования.
            </p>
          </div>
          <img src={samples} alt="slide-3" />
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">Оборудование</p>
            <p className="product__modules-description-text">
              Модуль предоставляет инструменты для учета амортизации и
              калибровки оборудования в лаборатории. <br />
              Это позволяет эффективно управлять ресурсами и обеспечивать
              точность получаемых результатов.
            </p>
          </div>
          <img src={equipment} alt="slide-4" />
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">Dashboard</p>
            <p className="product__modules-description-text">
              Модуль предоставляет обобщенную статистику и ключевые показатели
              на одном экране, обеспечивая оперативный мониторинг и принятие
              информированных решений.
            </p>
          </div>
          <img src={dashboard} alt="slide-5" />
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">Отчетность</p>
            <p className="product__modules-description-text">
              Модуль обеспечивает простое и быстрое создание отчетов,
              предоставляя пользователю гибкие инструменты для форматирования и
              анализа данных.
            </p>
          </div>
          <img src={report} alt="slide-6" />
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">BlendCheck</p>
            <p className="product__modules-description-text">
              Модуль разработан для обеспечения безошибочной навески в
              лабораторной среде. Система использует технологию сканирования
              штрих-кода на пробе, что исключает возможность ошибок при навеске.
            </p>
          </div>
          <img src={blendCheck} alt="slide-7" />
        </div>
      </div>
    </Slider>
  );
};

export default CarouselCemTest;
